export default {
    "success": true,
    "data": {
        "memes": [
            {
                "url": "./memes/meme2.jpg",
            },
            {
                "url": "./memes/meme3.jpg",
            },
            {
                "url": "./memes/meme4.jpg",
            },
            {
                "url": "./memes/meme5.jpg",
            },
            {
                "url": "./memes/meme6.jpg",
            },
            {
                "url": "./memes/meme7.jpg",
            },
            {
                "url": "./memes/meme8.jpg",
            },
            {
                "url": "./memes/meme9.jpg",
            },
            
            {
                "url": "./memes/meme10.jpg",
            },
            
            {
                "url": "./memes/meme11.jpg",
            },
            
            {
                "url": "./memes/meme12.jpg",
            },
            
            {
                "url": "./memes/meme13.jpg",
            },
            
            {
                "url": "./memes/meme14.jpg",
            },
            
            {
                "url": "./memes/meme15.jpg",
            },
            
            {
                "url": "./memes/meme16.jpg",
            },
            
            {
                "url": "./memes/meme17.jpg",
            },
            
            {
                "url": "./memes/meme18.png",
            },
            
            {
                "url": "./memes/meme19.jpg",
            },
            
            {
                "url": "./memes/meme20.jpg",
            },
        ]
    }
}